/**
 * Overrides: withShortcut
 * By Lenderson Macedo
 * Check framer.place for more
 */
import type { ComponentType } from "react"
import { createShortcut as a } from "https://framer.b-cdn.net/v1/lib/overrides/shortcuts/OJQ0zk4mmT/index.js"

export function withShortcut(C): ComponentType {
    return a(C)
}
